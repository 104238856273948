// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: "http://localhost:8080",
  publicFolder: "http://localhost:8080/public",
  // apiUrl: "https://dealer.pp-performance-test.de:4530",
  // publicFolder: "https://dealer.pp-performance-test.de:4530/public",
  payPalClientId: "AeK2j1JPceJZoxQ8R6xCiepHeda5IxTqTqmYpUgko9KOsqD56MUvET1r_3lmKENm6wjqwNk6FGejcwlx",
  stripePublishableKey: "pk_test_51QX36cHpQK6Br9HFmO43KllBIdev5SCui0KYyDaDaAjn3iDkmhyqCBQvhxp3JFZ80UkvbC1pJa9o4ogyUOsR03Ry00OKXcUTK2",
  // old stripe key
  //stripePublishableKey: "pk_test_51QV93oDkG0EG6ht2VJmLcDMOaxmcdnxCYxMuYCey4ZAcTPW6QoxyU8b2K3pThVgTgbentng71iIsyni9xBrZZo8m00GNOMwdYJ",
  pusher: {
    key: "f0e7f0e0ce36ee7d18ba",
    cluster: "eu",
  },
};


